<template>
  <v-layout>
    <item-list
      :listId="table"
      :list="list"
      :availableHeaders="headers"
      :refreshColumnDecoration="refreshColumnDecoration"
      decorateColumnAction="orders/decorateColumn"
      externalPagination
      serverside
      class="mt-2"
    >
      <template v-slot:total="{ item }">
        {{
          getOrderTotalByCurrency(item)
            .map((currencyTotal) => `${formatAmount(currencyTotal.amount)} ${getCurrencyName(currencyTotal.currencyId)}`)
            .join(' + ')
        }}
      </template>
      <template v-slot:refunded="{ item }">
        <div v-if="typeof item.refunded === 'boolean'">
          <tooltip-button v-if="item.refunded"
            :disabled="disconnectedPeripheralService || !selectedPrinter"
            :disabledTooltip="!(disconnectedPeripheralService || !selectedPrinter)"
            :tooltipText="disconnectedPeripheralService ? $t('peripherals.warnings.disconnected_peripheral_service') : $t('peripherals.warnings.printer_not_configured')"
            @click="printRefundTicket(item)"
            :loading="loading.printRefundTicketOrderId === item.orderRefundId"
            icon="mdi-printer"
            outlined
            isIcon
          />
          <v-btn outlined icon v-if="item.refunded" @click="downloadRefundTicket(item)" class="ml-2">
            <v-icon>mdi-receipt</v-icon>
          </v-btn>
        </div>
        <loading-field v-else :willHaveValue="true" />
      </template>
      <template v-slot:appendActions="{ item }">
        <v-spacer />
        <v-menu
          open-on-hover
          v-model="item.show"
          :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <!-- ACTIVADOR -->
            <v-btn
              icon
              small
              outlined
              v-on="on">
            <v-icon>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list class="d-flex px-2">
            <div v-if="typeof item.refunded === 'boolean' || !isPermissionActive('LIST_ORDER_REFUNDS_BY_ORDER_ID')">
              <tooltip-button
                v-if="(!item.refunded || !isPermissionActive('LIST_ORDER_REFUNDS_BY_ORDER_ID')) && orderHasAmount(item) && orderCreatedByAppServer(item)"
                :loading="refundingOrder && refundingOrder.id === item.id"
                :disabled="!operatorShift || !isPermissionActive('LIST_ORDER_REFUNDS_BY_ORDER_ID')"
                :disabledTooltip="!(!operatorShift || !isPermissionActive('LIST_ORDER_REFUNDS_BY_ORDER_ID'))"
                :text="$t('actions.refund')"
                :tooltipText="!operatorShift ? $t('shifts.required_shift.open_shift_required') : $t('default_content.disabled_header_permission', { resource: getResourceName('LIST_ORDER_REFUNDS_BY_ORDER_ID') }) "
                buttonClass="mr-2"
                outlined
                @click="openRefundModal(item)"
              />
            </div>
            <loading-field v-else :willHaveValue="true" />
            <v-btn outlined icon @click="openTicket(item.id)" class="mr-2">
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
            <tooltip-button
              :disabled="disconnectedPeripheralService || !selectedPrinter"
              :disabledTooltip="!(disconnectedPeripheralService || !selectedPrinter)"
              :tooltipText="disconnectedPeripheralService ? $t('peripherals.warnings.disconnected_peripheral_service') : $t('peripherals.warnings.printer_not_configured')"
              @click="printTicket(item.id)"
              :loading="loading.printTicketOrderId === item.id"
              icon="mdi-printer"
              outlined
              isIcon
            />
          </v-list>
        </v-menu>
      </template>
    </item-list>
    <confirm-modal
      ref="confirmModal"
      :triggerOpen="triggerOpenConfirm"
      :title="$t('orders.order_refund')"
      :text="$t('orders.confirm_refund')"
      :acceptText="$t('actions.confirm')"
      :cancelText="$t('actions.cancel')"
      @accept="continueRefund()"
      :hideActions="!isSupervisor && hasTempToken"
      @closed="refundingOrder = null"
      keepOpenAfterAccept
    >
      <template v-slot:content>
        <v-card-actions v-if="errors.refundError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="error" class="mr-2">mdi-alert-circle-outline</v-icon>{{$t('orders.error.refund')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text color="primary" @click="refundOrder()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-card-actions v-if="errors.ticketGenerationError || errors.printingTicketError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>{{$t('orders.error.receipt')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text v-if="errors.ticketGenerationError" color="primary" @click="getRefundTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
              <v-btn text v-if="errors.printingTicketError" color="primary" @click="printRefundTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-layout v-if="loading.refundOrder || loading.getRefundTicket || loading.printRefundTicket" align-center justify-center class="my-4 grey--text body">
          <i>{{
            loading.refundOrder
              ? $t('orders.loading.complete')
              : loading.getRefundTicket
                ? $t('orders.loading.ticket')
                : $t('orders.loading.print')
          }}</i><loading-dots />
        </v-layout>
      </template>
      <template v-slot:appendAction>
        <v-layout v-if="orderRefundId && (loading.refundOrder || loading.getRefundTicket || loading.printRefundTicket)" justify-end>
          <v-btn color="primary" outlined @click="refundingOrder = null">
            {{$t('actions.close')}}
          </v-btn>
        </v-layout>
      </template>
    </confirm-modal>
    <supervisor-modal :triggerOpen="openSupervisorModal" @finished="refundOrder(); hasTempToken = true" />
  </v-layout>
</template>

<script>
import { getOrderTotalByCurrency, orderHasAmount, orderCreatedByAppServer } from '@/utils/OrderUtils' 
import { mapState, mapGetters } from 'vuex'
import { OrderRequests } from '@/services/order.requests'
import ServerDirections from '@/config/server-directions'
import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
import permissionMixins from '@/mixins/Permission'
import moneyFormat from '@/mixins/MoneyFormat'
const orderService = new OrderRequests()

export default {
  props: {
    list: Array,
    refreshColumnDecoration: Date,
    passId: String,
    listId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      table: this.listId,
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('fields.reference'), path: 'reference', show: true },
        { text: this.$t('fields.created'), path: 'createdAt', show: true, isFullDate: true, sortable: true },
        { text: this.$t('fields.station'), path: 'station.name', show: true, willHaveValue: (item) => !!item.stationId },
        { text: this.$t('fields.service'), path: 'service.name', show: true, willHaveValue: (item) => !!item.serviceId, permission: 'LIST_SERVICES' },
        { text: this.$t('fields.total'), path: 'total', show: true },
        { text: this.$t('fields.payment_method'), path: 'paymentMethodType', show: true, permission: 'GET_ORDER_TRANSACTIONS' },
        { text: this.$t('orders.refunded'), path: 'refunded', show: true, permission: 'LIST_ORDER_REFUNDS_BY_ORDER_ID' }
      ],
      triggerOpenConfirm: null,
      refundingOrder: null,
      orderRefundId: null,
      getOrderTotalByCurrency,
      orderHasAmount,
      orderCreatedByAppServer,
      base64Ticket: null,
      errors: {
        refundError: false,
        ticketGenerationError: false,
        printingTicketError: false
      },
      loading: {
        refundOrder: false,
        getRefundTicket: false,
        printRefundTicket: false,
        printTicketOrderId: null,
        printRefundTicketOrderId: null
      },
      openSupervisorModal: null,
      hasTempToken: false
    }
  },
  computed: {
    ...mapState(['pagination', 'state']),
    ...mapState({
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService,
      selectedPrinter: state => state.peripherals.selectedPrinter
    }),
    ...mapGetters({
      operatorShift: 'shifts/operatorShift',
      isSupervisor: 'shifts/isSupervisor',
      getCurrencyName: 'configuration/getCurrencyName',
      authToken: 'auth/authToken'
    })
  },
  methods: {
    openRefundModal (order) {
      this.refundingOrder = order
      this.triggerOpenConfirm = new Date()
      this.hasTempToken = false
    },
    refundOrder () {
      this.errors.refundError = false
      this.loading.refundOrder = true
      if (!this.$refs.confirmModal.open) {
        this.triggerOpenConfirm = new Date()
      }
      orderService.refund(this.refundingOrder).then((orderRefundId) => {
        this.$emit('refund')
        this.orderRefundId = orderRefundId
        this.getRefundTicket()
      }).catch(() => {
        this.errors.refundError = true
      }).finally(() => {
        this.loading.refundOrder = false
      })
    },
    getRefundTicket () {
      this.errors.ticketGenerationError = false
      this.loading.getRefundTicket = true
      orderService.getRefundTicket(this.orderRefundId).then((base64Ticket) => {
        this.base64Ticket = base64Ticket
        this.printRefundTicket()
        this.$store.commit('state/setMessage', { text: this.$t('orders.success.refund'), color: 'success' })
      }).catch(() => {
        this.errors.ticketGenerationError = true
      }).finally(() => {
        this.loading.getRefundTicket = false
      })
    },
    printRefundTicket () {
      this.errors.printingTicketError = false
      this.loading.printRefundTicket = true
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {
        this.refundingOrder = null
        this.loading.printRefundTicket = false
      }, () => {
        this.errors.printingTicketError = true
        this.loading.printRefundTicket = false
      })
    },
    openTicket (orderId) {
      const route = this.passId
        ? ServerDirections.POS_PASSES_ID_ORDER_ID_TICKET.replace('{passId}', this.passId).replace('{orderId}', orderId)
        : ServerDirections.POS_ORDERS_ID_TICKET.replace('{id}', orderId)
      window.open(`${route}?x-api-key=${this.authToken}`)
    },
    printTicket (orderId) {
      this.loading.printTicketOrderId = orderId
      orderService.getTicket(orderId, this.passId).then((base64Pdf) => {
        this.sendPrinterAction(base64Pdf, 'application/pdf', () => {
          this.$store.commit('state/setMessage', { text: this.$t('peripherals.printer.success'), color: 'success' })
          this.loading.printTicketOrderId = null
        }, () => {
          this.$store.commit('state/setMessage', { text: this.$t('orders.error.receipt'), color: 'error' })
          this.loading.printTicketOrderId = null
        })
      }).catch(() => {
        this.loading.printTicketOrderId = null
        this.$store.commit('state/setMessage', { text: this.$t('orders.error.receipt'), color: 'error' })
      })
    },
    printRefundTicket ({ orderRefundId }) {
      this.loading.printRefundTicketOrderId = orderRefundId
      orderService.getRefundTicket(orderRefundId).then((base64Pdf) => {
        this.sendPrinterAction(base64Pdf, 'application/pdf', () => {
          this.$store.commit('state/setMessage', { text: this.$t('peripherals.printer.success'), color: 'success' })
          this.loading.printRefundTicketOrderId = null
        }, () => {
          this.$store.commit('state/setMessage', { text: this.$t('orders.error.receipt'), color: 'error' })
          this.loading.printRefundTicketOrderId = null
        })
      }).catch(() => {
        this.loading.printRefundTicketOrderId = null
        this.$store.commit('state/setMessage', { text: this.$t('orders.error.receipt'), color: 'error' })
      })
    },
    downloadRefundTicket (order) {
      const route = ServerDirections.POS_ORDER_REFUNDS_ID_TICKET.replace('{id}', order.orderRefundId)
      window.open(`${route}?x-api-key=${this.authToken}`)
    },
    continueRefund () {
      if (this.isSupervisor) {
        this.refundOrder()
      } else {
        this.$refs.confirmModal.hide()
        this.openSupervisorModal = new Date()
      }
    }
  },
  watch: {
    refundingOrder (v) {
      if (!v) {
        this.errors = {
          refundError: false,
          ticketGenerationError: false,
          printingTicketError: false
        }
        this.orderRefundId = null
        if (this.$refs.confirmModal) {
          this.$refs.confirmModal.cancel()
        }
      }
    }
  },
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    LoadingField: () => import('@/components/app-components/LoadingField.vue'),
    LoadingDots: () => import('@/components/app-components/LoadingDots.vue')
  },
  mixins: [
    moneyFormat,
    printerMixins,
    peripheralMixins,
    permissionMixins
  ]
}
</script>
