import store from '@/store'
function getOrderTotalByCurrency (order) {
  if (!order.orderLines.length) return null
  var totals = []
  order.orderLines.forEach(orderLine => {
    if (!orderLine.parentOrderLine) {
      orderLine.price.forEach((price) => {
        const amount = price.amount * orderLine.quantity
        const currencyId = price.currencyId
        const index = totals.findIndex(total => total.currencyId === price.currencyId)
        if (index === -1) {
          totals.push({ amount, currencyId })
        } else {
          totals[index].amount += amount
        }
      })
    }
  })
  return totals
}

function orderHasAmount (order) {
  const totalByCurrency = getOrderTotalByCurrency(order)
  return totalByCurrency[0] && totalByCurrency[0].amount !== 0
}

function orderCreatedByAppServer (order) {
  return order.serviceId === store.getters['configuration/appServerServiceId']
}

export {
  getOrderTotalByCurrency,
  orderHasAmount,
  orderCreatedByAppServer
}
